import Header from "../../layouts/Header";
import { ReactElement, useEffect, useState } from "react";
import { setLoading } from "../../services/reducers/homeSlice";
import { useDispatch } from "react-redux";
import restApi from "../../services/restApi";
import { useNavigate } from "react-router-dom";

const Catalog = () =>{
  const dispatch = useDispatch()
  const [data, setData] = useState<any>()
  const [tab, setTab] = useState<any>('catalogs');
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState<any>();
  const [query, setQuery] = useState<any>('');
  const tabTitles = {
    priceList: 'لیست قیمت فروش',
    pictures: 'عکس ها',
    videos: 'ویدیو ها',
    catalogs: 'کاتالوگ ها',
  }

  const list = () => {
    const rows: ReactElement[] = [];

    let tabFilter;
    data?.filter((e) => {
      if (tab == 'videos'){
        tabFilter = e.media.mime == 'video/mp4'
      } else if (tab == 'pictures'){
        tabFilter = e.media.mime.includes('image')
      } else if (tab == 'catalogs'){
        tabFilter = e.media.mime == 'application/pdf'
      } else {
        tabFilter = e.media.mime.includes('sheet')
      }

      return tabFilter && (brand ? e.brandId == brand : true) && e.title.toLowerCase().includes(query.toLowerCase());
    }).map((article, index)=>{
      rows.push(
        <a className="blogCard clickable" target="_blank" href={article.media.url}>
          {tab == 'pictures' && <img className="blogCardImg" src={article.media.url}/> }
          {tab == 'catalogs' && <img className="blogCardImg" src='/img/pdf-logo.png'/> }
          {tab == 'priceList' && <img className="blogCardImg" src='/img/excel-logo.png'/> }
          {tab == 'videos' && <img className="blogCardImg" src={article.media.url}/> }
          <p className="cardTitle">
            {article.title}
          </p>
          <a className="cardLink">
            <p>مشاهده همه</p>
            <i></i>
          </a>
        </a>
      )}
    )
    
    return rows;
  }

  const fetchData = async () =>{
    dispatch(setLoading(true));
    
    const res = await Promise.all([
      await restApi(process.env.REACT_APP_BASE_URL + '/catalog' ).get(),
      await restApi(process.env.REACT_APP_BASE_URL + '/brand' ).get(),
    ])

    if(res[0].code == 200) {
      setData(res[0].data);
    }

    if(res[1].code == 200) {
      setBrands(res[1].data);
    }

    dispatch(setLoading(false));
  };
  
  useEffect( () => {
    fetchData();
  },[])

  return(
    <>
      <Header />
      <div className="categoryBanner"></div>
      <main className="singleProductMain blogbackground">
        <div className="mainHeader">
          <div className="catalogHeader keepRight">
            <div className="seaechBox keepRight">
              <i className="magnifierIcon"></i>
              <input className="searchInput" placeholder="جستجو" onChange={(e: any) => setQuery(e.target.value)}></input>
            </div>
          </div>
          {Object.entries(tabTitles).map(([key, value], index) => <span className={`titleTabs ${tab == key ? 'active' : ''}`} onClick={() => setTab(key)}>{value}</span>)}
          <div className="catalogHeader">
          <select className="selectBox keepRight" onChange={(e: any) => setBrand(e.target.value)}>
            {brands.map((e) => <option value={e.id}>{e.title}</option>)}
          </select>
          <span>: برند </span>
          </div>
        </div>
        <section className="blogContainer">
        {/*<div className="BlogcardContainer">*/}
          {list()}
        {/*</div>*/}
        </section>
      </main>
    </>
  )
}
export default Catalog;