import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { popupSlice } from '../../../services/reducers';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import { Sidebar } from '../Layout/Sidebar';
import AddCatalog from './AddCatalog';
import EditCatalog from './EditCatalog';

const EditBrand = () => {
  const [item, setItem] = useState<any>();
  const [title, setTitle] = useState('');
  const [externalCode, setExternalCode] = useState('');
  const [catalogs, setCatalogs] = useState<any>([]);
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const deleteCatalog = async (index, id) => {
    if(confirm('آیا مطمئن هستید؟')) {
      if (id){
        dispatch(setLoading(true));

        const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/catalog/' + id, true).delete({})

        if (res.code == 204){
          setCatalogs(catalogs.filter((k, j) => j != index - 1));

          Swal.fire({
              title: 'موفق',
              text: 'کاتالوگ با موفقیت حذف شد',
              icon: 'success',
              confirmButtonText: 'متوجه شدم'
            });
          } else {
            Swal.fire({
              title: 'ناموفق',
              text: res?.data,
              icon: 'error',
              confirmButtonText: 'متوجه شدم'
            });
        }

        dispatch(setLoading(false));

      } else {
        setCatalogs(catalogs.filter((k, j) => j != index - 1));
      }
    }
  };

  const send = async () => {
    dispatch(setLoading(true));

    let formData = new FormData();

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/' + id, true).put({
      title: title,
      description: description,
      externalCode: externalCode
    });


    if (catalogs.filter(e => !e.id).length > 0) {
      catalogs.filter(e => !e.id).map((e) => {
        formData.append('catalogFiles', e.file.data)
        formData.append('catalogTitles[]', e.title)
        formData.append('catalogSorts[]', e.sort)
      });
      await restApi(process.env.REACT_APP_BASE_URL + `/admin/brand/catalog/create/` + id, true).upload(formData);

    }
    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: 'برند با موفقیت ویرایش شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const fetchItem = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/' + id, true).get();

    if (res.code == 200) {
      setTitle(res.data.title)
      setDescription(res.data.description)
      setExternalCode(res.data.externalCode)
      setCatalogs(res.data?.catalogs)
      setItem(res.data)
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchItem();
  }, []);

  return (
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
           ارسال
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه برند</h1>
             <h1 className="dashBoardTitle">ویرایش برند </h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate('/dashboard/brand/')}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات برند</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)} value={title || item?.title}/>
            <label className="sideBarTitle" htmlFor="infoTitle">کد خارجی</label>
            <input className="addInput" id="infoTitle" name="externalCode" onChange={(input: any) => setExternalCode(input.target.value)} value={externalCode || item?.externalCode}/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount"  onChange={(input: any) => setDescription(input.target.value)} value={description || item?.description}/>
          </form>
        </section>
        <section className="catalogContainer">
          <h1 className="alignSelfEnd">کاتالوگ</h1>
          <table className="catalog">
            <thead>
            <tr>
              <th>ردیف</th>
              <th>عنوان</th>
              <th>عکس</th>
              <th>چینش</th>
              <th className="width100p">
                <span className="dashboardHeader addCatalog clickable" onClick={() => dispatch(popupSlice.middle(<AddCatalog setCatalogs={setCatalogs} />))}>
                  <i className="addPlus"></i>
                  افزودن کاتالوگ جدید
                </span>
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {catalogs?.map((e, index) =>
              <tr>
                <td>{++index}</td>
                <td>{e.title}</td>
                <td><img className="maxWidth100" src={e.file?.preview || e.media?.url}/></td>
                <td>{e.sort}</td>
                <td className="around">
                  <i className="deleteSvg clickable" onClick={() => deleteCatalog(index, e.id)}></i>
                  <i className="editSvg clickable" onClick={() => dispatch(popupSlice.middle(<EditCatalog setCatalogs={setCatalogs} catalog={e} />))}></i>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </section>
      </main>
      </body>
    </>
  );
};
export default EditBrand;