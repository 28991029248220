 import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import restApi from '../../../services/restApi';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { GuaranteeCard } from '../Guarantee/GuaranteeCard';

const OrderGuarantee = () => {
  const [data, setData] = useState<any>([]);
  const { id: paramId } = useParams()
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  const fetchData = async () => {
    // dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/order/guarantee/' + paramId, true).get()

    setData(res.data)
    await setTimeout(() => { reactToPrintFn()}, 300)
    //
    // dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const list = () => {
    return data.sort((a, b) => b.sku.localeCompare(a.sku)).sort((a, b) => b.brandTitle.localeCompare(a.brandTitle)).map((guarantee) =>
        <GuaranteeCard key={guarantee.id} data={guarantee} />
    )
  }

  return (
    <main className='guaranteeFactorContainer' ref={contentRef}>
      {list()}
    </main>
  );
};

export default OrderGuarantee;;
