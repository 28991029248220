import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { popupSlice } from '../../../services/reducers';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';

const EditCatalog = ({ setCatalogs, catalog }: {
  setCatalogs: React.Dispatch<React.SetStateAction<any[]>>
  catalog: any
}) => {
  const dispatch: any = useDispatch();
  const [title, setTitle] = useState(catalog.title);
  const [sort, setSort] = useState(catalog.sort);
  const [file, setFile] = useState({
    preview: '',
    data: '',
  });

  const send = async () => {
    if (catalog.id) {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append('file', file.data)
      formData.append('title', title)
      formData.append('sort', sort)

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/catalog/update/' + catalog.id, true).upload(formData);

      if (res.code == 200) {
        Swal.fire({
          title: 'موفق',
          text: 'کاتالوگ با موفقیت ویرایش شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        });
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        });
      }

      dispatch(setLoading(false));
    } else {

    }
    dispatch(popupSlice.hide());
  };

  return (
    <div className="catalogModal">
      <h1>افزودن کاتالوگ</h1>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">عنوان</label>
        <input className="addInput width300p" id="infoTitle" defaultValue={catalog.title} onChange={(input: any) => setTitle(input.target.value)}/>
      </span>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">چینش</label>
        <input className="addInput width300p" id="infoTitle" defaultValue={catalog.sort} onChange={(input: any) => setSort(input.target.value)}/>
      </span>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">فایل</label>
        <input className="addInput width300p" type="file" id="infoTitle" onChange={(e: any) => setFile({
          preview: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
        })}/>
      </span>
      <span className="dashboardHeader addCatalog clickable keepRight" onClick={() => {
        send();
        // setCatalogs((prev) => [...prev, {
        //   title: title,
        //   sort: sort,
        //   file: file
        // }])
      }}>ثبت</span>
    </div>
  );
};

export default EditCatalog;
